import Checkbox from '../../common/Checkbox';
import React from 'react';
import { Category, CategoryName, Item, Spacer } from './FilterCategory.styles';
import { FormsFiltersType } from 'store/forms/forms.reducer';
import { Marginator, Truncate } from 'common/common.styles';
import { RedDot } from './FilterBar.styles';
import { TranslatePropsType } from 'common/common.types';
import { isFilterSupported } from '../utils';
import { values } from 'ramda';
import { withTranslation } from 'react-i18next';

interface FilterCategoryPropsType extends TranslatePropsType {
  category: string;
  filters: FormsFiltersType;
  handleCheckboxChange: (branch: string, name: string) => void;
  hasNewData: boolean;
  uiFilters: {};
}

const FilterBar = ({
  category,
  filters: { assignees: assigneesFilters, simple: simpleFilters },
  handleCheckboxChange,
  hasNewData,
  t,
  uiFilters,
}: FilterCategoryPropsType) => (
  <Category key={`category-${category}`}>
    <CategoryName newData={category === 'new' && hasNewData}>
      {t(`filterCategories.${category}`) as string}

      {category === 'new' && hasNewData && (
        <Marginator margin={{ left: 8 }}>
          <RedDot />
        </Marginator>
      )}
    </CategoryName>

    {uiFilters[category].map((name: string, index: number) =>
      name ? (
        name === 'assignees' ? (
          values(assigneesFilters).map(
            ({ count, label, isEnabled, id }: any) => (
              <Item key={id} data-test={`filter-${id}`}>
                <Checkbox
                  isChecked={isEnabled || false}
                  isDisabled={!count}
                  label={
                    <>
                      <Truncate width={105}>{label}</Truncate>&nbsp;
                      <span>{`(${count})`}</span>
                    </>
                  }
                  marginTop={-7}
                  onChange={() => handleCheckboxChange('assignees', id)}
                  secondaryColor={'teal'}
                />
              </Item>
            ),
          )
        ) : (
          isFilterSupported(name) && (
            <Item key={name} data-test={`filter-${name}`}>
              <Checkbox
                isChecked={simpleFilters[name].isEnabled}
                isDisabled={!simpleFilters[name].count}
                label={`${t(`filters.${name}`)} (${simpleFilters[name].count})`}
                marginTop={-7}
                onChange={() => handleCheckboxChange('simple', name)}
                secondaryColor={'teal'}
              />
            </Item>
          )
        )
      ) : (
        <Spacer key={`spacer-${category}-${index}`} />
      ),
    )}
  </Category>
);

export default withTranslation('FilterBar')(FilterBar);
